<template>
  <div
    class="
      widgetContainer widgetContainer--full-screen
      approved
      bg-white
      cardActivated
    ">
    <div class="widgetContainer__body">
      <LottieAnimation animation-name="success" reference="card-activated-success" :loop="true" />
      <p class="title">
        {{ $t('card_activate_success_title') }}
      </p>
      <p class="description">
        {{ $t('card_activate_success_desc') }}
      </p>
    </div>
    <div class="el-drawer__footer">
      <el-button type="primary" @click="drawerClose" class="width-100 el-button__brand brand">
        {{ $t('done') }}
      </el-button>
    </div>
  </div>
</template>
<script>
import LottieAnimation from '@/components/LottieAnimation.vue';
export default {
  components: {
    LottieAnimation
  },
  created() {
    this.$emit('refresh');
  },
  methods: {
    done() {
      this.drawerClose();
    }
  }
};
</script>
<style lang="scss">
.cardActivated {
  &.approved {
    text-align: center;
    align-items: center;
    display: flex;
    .widgetContainer__body {
      padding: 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    .welcome__illustration {
      margin-bottom: 36px;
    }

    .title,
    .description {
      padding-left: 40px;
      padding-right: 40px;
    }

    .title {
      font-size: 24px;
      line-height: 30px;
      padding: 0 0 16px 0;
    }

    .description {
      color: rgba(#3c3c43, 0.6);
      font-size: 14px;
      line-height: 20px;
    }

    .el-button {
      margin-top: auto;
    }
  }
}
</style>
